(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@pvw/plmvisweb"), require("Api @atlas/api-javascript-sdk/no/splm"), require("Api @atlas/api-javascript-sdk/no/Object"), require("Api @atlas/api-javascript-sdk/no/TransientObject"), require("Api @atlas/api-javascript-sdk/no/TaggedObjectCollection"));
	else if(typeof define === 'function' && define.amd)
		define(["@pvw/plmvisweb", "Api @atlas/api-javascript-sdk/no/splm", "Api @atlas/api-javascript-sdk/no/Object", "Api @atlas/api-javascript-sdk/no/TransientObject", "Api @atlas/api-javascript-sdk/no/TaggedObjectCollection"], factory);
	else if(typeof exports === 'object')
		exports["AtlasSelTools"] = factory(require("@pvw/plmvisweb"), require("Api @atlas/api-javascript-sdk/no/splm"), require("Api @atlas/api-javascript-sdk/no/Object"), require("Api @atlas/api-javascript-sdk/no/TransientObject"), require("Api @atlas/api-javascript-sdk/no/TaggedObjectCollection"));
	else
		root["AtlasSelTools"] = factory(root["Visualization"], root["Api @atlas/api-javascript-sdk/no/splm"], root["Api @atlas/api-javascript-sdk/no/Object"], root["Api @atlas/api-javascript-sdk/no/TransientObject"], root["Api @atlas/api-javascript-sdk/no/TaggedObjectCollection"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__pvw_plmvisweb__, __WEBPACK_EXTERNAL_MODULE__atlas_api_javascript_sdk_no_splm__, __WEBPACK_EXTERNAL_MODULE__atlas_api_javascript_sdk_no_Object__, __WEBPACK_EXTERNAL_MODULE__atlas_api_javascript_sdk_no_TransientObject__, __WEBPACK_EXTERNAL_MODULE__atlas_api_javascript_sdk_no_TaggedObjectCollection__) => {
return 